<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-md-12 text-left">
                <h2 class="card-title">CA PAR LEVIERS</h2>
              </div>
              <div class="col-md-12">
                <div class="row">

                  <base-input class="col-md-3" :label="$t('table.columns.Startdate')" :error="getError($t('table.columns.Startdate'))">
                    <el-date-picker
                      :name="$t('table.columns.Startdate')"
                      v-validate="'required'"
                      v-model="StartDate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      :placeholder="$t('table.columns.Startdate').toUpperCase()"
                      :disabled="$apollo.queries.statspendpub.loading"
                    ></el-date-picker>
                  </base-input>

                  <base-input class="col-md-3" :label="$t('table.columns.Enddate')" :error="getError($t('table.columns.Enddate'))">
                    <el-date-picker
                      :name="$t('table.columns.Enddate')"
                      v-validate="'required'"
                      v-model="EndDate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      :placeholder="$t('table.columns.Enddate').toUpperCase()"
                      :disabled="$apollo.queries.statspendpub.loading"
                    ></el-date-picker>
                  </base-input>

                  <base-input class="col-md-3" label="LEVIER">
                    <el-select
                      class="select-primary"
                      v-model="Levier"
                      placeholder="LEVIER"
                      clearable
                      :disabled="$apollo.queries.statspendpub.loading"
                    >
                      <el-option class="select-primary" label="FACEBOOK" value="facebook"></el-option>
                      <el-option class="select-primary" label="GOOGLE" value="google"></el-option>
                      <el-option class="select-primary" label="BING" value="bing"></el-option>
                      <el-option class="select-primary" label="PERF" value="perf"></el-option>
                      <el-option class="select-primary" label="SMS" value="sms"></el-option>
                    </el-select>
                  </base-input>

                  <base-input class="col-md-3" label="POLE">
                    <el-select
                      class="select-primary"
                      v-model="Pole"
                      placeholder="POLE"
                      :disabled="$apollo.queries.statspendpub.loading"
                      clearable
                    >
                      <el-option class="select-primary" label="WEEDO PERF" value="weedo_perf"></el-option>
                      <el-option class="select-primary" label="WEEDO MARKET" value="weedo_market"></el-option>
                    </el-select>
                  </base-input>

                  <base-input class="col-md-3" label="CATEGORIE">
                    <el-select
                      class="select-primary"
                      v-model="Categorie"
                      :disabled="$apollo.queries.statspendpub.loading"
                      placeholder="CATEGORIE"
                      clearable
                    >
                      <el-option class="select-primary" label="ANIMAUX" value="animaux"></el-option>
                      <el-option class="select-primary" label="AUTO" value="auto"></el-option>
                      <el-option class="select-primary" label="EMPRUNTEUR" value="emprunteur"></el-option>
                      <el-option class="select-primary" label="HABITATION" value="habitation"></el-option>
                      <el-option class="select-primary" label="SANTE" value="Sante"></el-option>
                    </el-select>
                  </base-input>

                  <base-input class="col-md-3" label="PROGRAMME">
                    <el-select filterable class="select-primary"
                      v-model="Programme"
                      :disabled="$apollo.queries.statspendpub.loading"
                      :placeholder="$t('table.columns.Product').toUpperCase()"
                      clearable>
                      <el-option
                        v-for="option in list_programme_spend_reseau"
                        class="select-primary text-uppercase"
                        :value="option.nom_programme"
                        :label="option.nom_programme"
                        :key="option.nom_programme">
                      </el-option>
                    </el-select>
                  </base-input>

                  <base-input class="col-md-3" label="TYPE SANTE" v-if="Categorie == 'Sante' &&  Pole == 'weedo_market'">
                    <el-select
                      class="select-primary"
                      v-model="Type_Sante"
                      placeholder="CATEGORIE"
                      clearable
                    >
                      <el-option class="select-primary" label="SALARIES" value="salaries"></el-option>
                      <el-option class="select-primary" label="SENIORS" value="serniors"></el-option>
                    </el-select>
                  </base-input>

                  <div class="col-md-12 float-right pt-2">
                    <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                      <base-button v-if="$apollo.queries.statspendpub.loading"
                        loading
                        class="btn btn-round btn-just-icon btn-default mt-0 d-block"
                        >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                      <base-button
                        class="btn btn-round btn-just-icon btn-primary"
                        v-else
                        @click="send"
                        >{{ $t("buttons.BtnSearch").toUpperCase() }}</base-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card card-chart">
          <div class="p-2" data="black">
            <div class="row">
              <div class="col-xl-1 col-6">
                <base-input>
                  <el-select
                    class="select-primary mb-3 pagination-select"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-xl-9 col-6">
                <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
              </div>
              <div class="col-xl-2 col-sm-6">
                <base-dropdown
                  title-classes="dropdown-toggle btn btn-primary btn-block btn-info"
                  title="EXPORT"
                >
                  <a class="dropdown-item" @click="ExportTable">CSV</a>
                </base-dropdown>
              </div>
            </div>
            <el-table :data="queriedData" class="customtable" v-loading="$apollo.queries.statspendpub.loading" show-summary row-class-name="booking-table-rows" :summary-method="getSummaries">
              <el-table-column
                label="levier"
                prop="levier"
                show-overflow-tooltip
                min-width="80"
              >
                <template scope="scope">
                  <i :class="icons_sm[scope.row.levier] + ' icon-large'" :title="scope.row.levier.toUpperCase()"></i>
                </template>
              </el-table-column>
              <el-table-column
                label="categorie"
                prop="categorie"
                show-overflow-tooltip
                min-width="110"
              >
                <template scope="scope">
                  <i :class="icons[scope.row.categorie] + ' icon-large'" :title="scope.row.categorie.toUpperCase()"></i>
                </template>
              </el-table-column>
              <el-table-column
                label="programme"
                prop="programme"
                show-overflow-tooltip
                min-width="120"
              >
              </el-table-column>
              <el-table-column
                label="TAGS"
                prop="nom_campagne"
                show-overflow-tooltip
                min-width="380"
              >
                <template scope="scope">
                  <template v-for="tag in scope.row.nom_campagne.split(',')" >
                    <label :disabled="disable_click" style="cursor: pointer;" @click="!disable_click ? openStatsTag(tag) : 1">{{ tag }}</label><br>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                label="pole"
                prop="pole"
                show-overflow-tooltip
                min-width="80"
              >
                <template scope="scope">
                  <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.pole == 'weedo_perf'">
                    <svg version="1.1" id="Calque_1" fill="#988fc3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                      <g>
                        <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                        <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                          c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                          h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                          />
                      </g>
                    </svg>
                  </button>
                  <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.pole == 'weedo_coreg'">
                    <svg version="1.1" id="Calque_1" fill="#54c4d5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                      <g>
                        <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                        <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                          c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                          h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                          />
                      </g>
                    </svg>
                  </button>
                  <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.pole == 'weedo_data'">
                    <svg version="1.1" id="Calque_1" fill="#f3ac9a" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                      <g>
                        <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                        <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                          c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                          h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                          />
                      </g>
                    </svg>
                  </button>
                  <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.pole == 'weedo_market'">
                    <svg version="1.1" id="Calque_1" fill="#588bbd" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                      <g>
                        <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                        <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                          c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                          h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                          />
                      </g>
                    </svg>
                  </button>
                </template>
              </el-table-column>
              <el-table-column
                label="lead"
                prop="lead"
                show-overflow-tooltip
                sortable
                min-width="100"
              ></el-table-column>
              <!-- <el-table-column
                label="Depenses"
                prop="spend"
                show-overflow-tooltip
                sortable
                min-width="125"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.spend, true, 2) + ' €' }}
                </template>
              </el-table-column> -->
              <el-table-column
                label="Depenses"
                prop="spend"
                show-overflow-tooltip
                sortable
                min-width="125"
              >
                <template scope="scope">
                  <div v-if="scope.row.levier == 'google'">{{ formatThounsends(addPecentage(scope.row.spend), true, 2) + ' €' }}</div>
                  <div v-else>{{ formatThounsends(scope.row.spend, true, 2) + ' €' }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="CA GLOBALE"
                prop="ca_global"
                show-overflow-tooltip
                sortable
                min-width="135"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ca_global, true, 2) + ' €' }}
                </template>
              </el-table-column>
              <el-table-column
                label="marge"
                prop="marge"
                show-overflow-tooltip
                sortable
                min-width="125"
              >
                <template scope="scope">
                  <label :style="(scope.row.marge <= 0) ? 'color: red' : 'color: green'">
                    {{ formatThounsends(scope.row.marge, true, 2) + ' €' }}
                  </label>
                </template>
              </el-table-column>
              <el-table-column
                label="% marge"
                prop="marge_prc"
                show-overflow-tooltip
                sortable
                min-width="125"
              >
                <template scope="scope">
                  <label :style="(scope.row.marge_prc <= 0) ? 'color: red' : 'color: green'">
                    {{ formatThounsends(scope.row.marge_prc, true, 2) + ' %' }}
                  </label>
                </template>
              </el-table-column>
              <el-table-column
                label="lead wm"
                prop="lead_wm"
                show-overflow-tooltip
                sortable
                min-width="115"
              >
              </el-table-column>
              <el-table-column
                label="lead wm potentiel"
                prop="lead_wm_potentiel"
                show-overflow-tooltip
                sortable
                min-width="180"
              >
              </el-table-column>
              <el-table-column
                label="vendu 3 fois"
                prop="lead_vendu_3_fois"
                show-overflow-tooltip
                sortable
                min-width="150"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.lead_vendu_3_fois) + " - " }}
                  {{ (scope.row.lead_wm_potentiel != 0) ? formatThounsends((scope.row.lead_vendu_3_fois * 100) / scope.row.lead_wm_potentiel, true, 2) : '0' }} %
                </template>
              </el-table-column>
              <el-table-column
                label="active collecté"
                prop="lead_wm_salaries"
                show-overflow-tooltip
                sortable
                min-width="165"
              >
              </el-table-column>
              <el-table-column
                label="sénior collecté"
                prop="lead_wm_seniors"
                show-overflow-tooltip
                sortable
                min-width="170"
              >
              </el-table-column>
              <el-table-column
                label="active vendu"
                prop="lead_wm_salaries_vendu"
                show-overflow-tooltip
                sortable
                min-width="155"
              >
              </el-table-column>
              <el-table-column
                label="sénior vendu"
                prop="lead_wm_seniors_vendu"
                show-overflow-tooltip
                sortable
                min-width="150"
              >
              </el-table-column>
            </el-table>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class>
                <p
                  class="card-category"
                >{{ $t('labels.Datatable.Showing') }} {{ from + 1 }} {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}</p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              ></base-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Table, TableColumn, DatePicker, Select, Option } from 'element-ui';
  import { BasePagination } from "src/components";
  import gql from 'graphql-tag'
  import XLSX from 'xlsx'
  import moment from 'moment'

  export default {
    apollo: {
      list_programme_spend_reseau: {
        query: gql`
        query {
          list_programme_spend_reseau {
            nom_programme
          }
        }`,
        update: data => data.list_programme_spend_reseau.sort((a, b) => a.nom_programme.toUpperCase().localeCompare(b.nom_programme.toUpperCase())),
      },
      statspendpub: {
        query: gql`
        query ($date_debut: String, $date_fin: String, $pole: String, $levier: String, $categorie: String, $nom_programme: String, $type_sante: String) {
          statspendpub(date_debut: $date_debut, date_fin: $date_fin, pole: $pole, levier: $levier, categorie: $categorie, nom_programme: $nom_programme, type_sante: $type_sante) {
            levier
            categorie
            nom_campagne
            pole
            lead
            id_compte
            spend
            ca_ed
            ca_an
            ca_wm
            ca_mg

            lead_wm
            lead_vendu_3_fois
            lead_wm_salaries
            lead_wm_seniors
            lead_wm_salaries_vendu
            lead_wm_seniors_vendu
          }
        }
        `,
        skip() {
          return this.skipQuery
        },
        variables() {
          return {
            date_debut: this.StartDate,
            date_fin: this.EndDate,
            pole: this.Pole,
            levier: this.Levier,
            categorie: this.Categorie,
            nom_programme: this.Programme,
            type_sante: (this.Categorie == 'Sante' &&  this.Pole == 'weedo_market') ? this.Type_Sante : ""
          };
        },
        result({ data, loader, networkStatus }) {
          this.skipQuery = true
          let tag = ""
          let SommeLead = 0
          let SommeCa = 0
          let SommeDepense = 0
          let spend = 0
          data.statspendpub.forEach(e => {
            if (e.pole == 'weedo_perf') {
              e.ca_global = parseFloat(e.ca_an)
            } else {
              e.ca_global = parseFloat(e.ca_mg) + parseFloat(e.ca_wm)
              // e.ca_global = parseFloat(e.ca_mg)
              // e.ca_global = parseFloat(e.ca_wm)
            }
            e.spend = parseFloat(e.spend)
            e.marge = e.ca_global - e.spend
            e.marge_prc = e.ca_global != 0 ? ((e.ca_global - e.spend) / e.ca_global * 100) : 0
            e.lead_wm_potentiel = e.lead_wm * 3;
            // programme
            tag = e.nom_campagne.split('_');
            e.programme = tag[1];
            // programme

            // e.nom_campagne = e.nom_campagne.replace(/,/g, '<br>')
            e.categorie = e.categorie.replace(/\s/g, '');

            SommeCa += parseFloat(e.ca_global)
            spend = e.spend
            if(e.levier == 'google'){
                let percentage = (2 / 100) * spend;
                spend = spend + percentage;
            }
            SommeDepense += parseFloat(spend)
            SommeLead += parseInt(e.lead)
          });
          this.SommeCa = SommeCa
          this.SommeDepense = SommeDepense
          this.SommeLead = SommeLead
        },
        fetchPolicy: 'network-only'
      }
    },
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [DatePicker.name]: DatePicker,
      [Select.name]: Select,
      [Option.name]: Option,
      BasePagination
    },
    data() {
      return {
        disable_click: false,
        SommeCa: 0,
        SommeDepense: 0,
        SommeLead: 0,
        skipQuery: true,
        EndDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        StartDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        Pole: "",
        Levier: "",
        Categorie: "",
        Programme: "",
        Type_Sante: "",
        pagination: {
          perPage: 150,
          currentPage: 1,
          perPageOptions: [ 50, 150, 300, 500],
          total: 0
        },
        total: 0,
        statspendpub: [],
        icons: {
          'auto' : 'fas fa-car', 'sante' : 'fas fa-heartbeat',
          'habitation' : 'fas fa-home', 'animaux' : 'fas fa-paw',
          'TNS Santé' : 'fas fa-people-arrows', 'Défiscalisation' : 'fas fa-chart-bar',
          'Protection juridique' : 'fas fa-balance-scale-right',
          'emprunteur' : 'tim-icons icon-bank'
        },
        icons_sm: {
          'facebook' : 'fa-brands fa-facebook',
          'google' : 'fa-brands fa-google',
          'sms' : 'fa-solid fa-comment-sms',
          'perf' : 'fa-solid fa-at'
        }
      }
    },
    computed: {
      queriedData() {
        this.total = this.statspendpub.length
        return this.statspendpub.slice(this.from, this.to)
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      }
    },
    methods: {
      async openStatsTag(tag) {
        this.disable_click = true;
        await this.$apollo.query({
          query: gql`query ($nom_campagne: String!) {
            getTagByName(nom_campagne: $nom_campagne) {
              id_campagne
              nom_campagne
            }
          }`,
          variables: {
            nom_campagne: tag
          },
        }).then(response => {
          let routeData = this.$router.resolve({
            name: "stats_shoots",
            params: { id_campagne: response.data.getTagByName.id_campagne }
          });
          window.open(routeData.href, "_blank");
          this.disable_click = false;
        }).catch((error) => {
          console.error(error)
          this.disable_click = false;
        })


      },
      renderHeaderMethod(h, { column, $index }) {
        return h("div", [
          h("span", column.label + "  ", {
            align: "left"
          }),
          h(
            "el-popover",
            {
              props: {
                placement: "top-start",
                width: "300",
                trigger: "hover"
              }
            },
            [
              h("label", "Vous avez 5 jours à partir de la date de livraison pour notifier un motif de dévalidation qui sera contrôlé pour nos services, en cas de motif légitime, le montant du lead sera crédité sur votre solde", {
              }),
              h("i", {
                class: "far fa-question-circle",
                style: "color:#588bbd;font-size: 18px !important",
                slot: "reference"
              })
            ]
          )
        ]);
      },
      ExportTable() {
        if (this.statspendpub.length) {
          let statSpendArray = JSON.parse(JSON.stringify(this.statspendpub));
          statSpendArray.forEach(function(v){
            v.ca_an = Number(v.ca_an).toFixed(2).replace(".", ",")
            v.ca_wm = Number(v.ca_wm).toFixed(2).replace(".", ",")
            v.ca_mg = Number(v.ca_mg).toFixed(2).replace(".", ",")
            if(v.levier == 'google'){
                let percentage = (2 / 100) * v.spend;
                v.spend = v.spend + percentage
            }
            v.spend = Number(v.spend).toFixed(2).replace(".", ",")
            v.ca_global = Number(v.ca_global).toFixed(2).replace(".", ",")
            v.marge_prc = Number(v.marge_prc).toFixed(2).replace(".", ",")
            v.marge = Number(v.marge).toFixed(2).replace(".", ",")

            delete v.__typename
            delete v.id_compte

            delete v.ca_ed
          });
          const fileName = 'STATS_PAR_LEVIER_'+this.StartDate+"-"+this.EndDate+'.csv'
          var statSpend = XLSX.utils.json_to_sheet(statSpendArray)
          statSpend = XLSX.utils.sheet_to_csv(statSpend, {FS:";"});
          // rename field
          statSpend = statSpend.replace("ca_an", "ca_weedo_perf")
          statSpend = statSpend.replace("ca_wm", "ca_weedo_maret")
          statSpend = statSpend.replace("ca_mg", "ca_mongustave")
          statSpend = statSpend.replace("spend", "depenses")
          statSpend = statSpend.replace("marge_prc", "% marge")
          // rename field
          const url = window.URL.createObjectURL(new Blob([statSpend]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        }
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      async send() {
        let validate
        await this.$validator.validateAll().then(isValid => {
          validate = isValid
        })
        if (!validate)
          return
        this.skipQuery = false
      },
      formatThounsends: function(value, fixe = false, number = 0){
        value != null ? value = value : value = 0
        if(fixe){
          value = value.toFixed(number)
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      },
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 5) {
            sums[index] = this.formatThounsends(this.SommeLead);
            return;
          } else if (index === 6) {
            sums[index] = this.formatThounsends(this.SommeDepense, true, 2) + " €";
            return;
          } else if (index === 7) {
            sums[index] = this.formatThounsends(this.SommeCa, true, 2) + " €";
            return;
          } else if (index === 8) {
            sums[index] = this.formatThounsends(this.SommeCa - this.SommeDepense, true, 2) + " €";
            return;
          } else if (index === 9) {
            sums[index] = this.formatThounsends(this.SommeCa != 0 ? ((this.SommeCa - this.SommeDepense) / this.SommeCa * 100) : 0, true, 2) + " %";
            return;
          } else {
            sums[index] = "";
            return;
          }
        });
        return sums;
      },
      CustomSort_leads(a, b) {
        try {
          return parseInt(a) < parseInt(b)
        } catch (error) {
          return false
        }
      },

      addPecentage(value){
        let percentage = (2 / 100) * value;
        return (value + percentage);
      },
    },
  }
</script>

<style>

  .booking-table-rows {
    text-transform: uppercase;
  }
  .customtable .cell{
    word-break: break-word;
  }

</style>

<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <div class="btn-group btn-group-toggle float-right mb-3" data-toggle="buttons">
          <router-link
            class="btn btn-round btn-just-icon btn-info"
            :to="{ name: 'Ajouter un Programme'}"
          >{{ $t("buttons.BtnAddProgram").toUpperCase() }}</router-link>
        </div>
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-6 text-left">
                <h4 class="card-title">{{ $t("labels.News").toUpperCase() }}</h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <p style="text-align: center">
              <strong>{{ $t("labels.LabelListprg").toUpperCase() }}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div id="carousel3d" v-if="!$apollo.queries.nouveautes.loading">
      <carousel-3d
        :perspective="0"
        :space="220"
        :display="7"
        :controls-visible="true"
        :controls-prev-html="'❬'"
        :controls-next-html="'❭'"
        :controls-width="30"
        :controls-height="30"
        :clickable="true"
        :autoplay="true"
        :autoplay-timeout="5000"
        :width="220"
        :height="300"
      >
        <slide class="costom_slide" v-for="(nouveaute, index) in nouveautes.slice(0,7)" :key="index" :index="index">
          <div class="card card-custom bg-white border-white border-0">
            <div class="card-custom-img" style="background-image: url(/img/cardprogram.jpg);">
              <h6></h6>
              <h6>
                <template v-if='ThematiqueSelects.ListThematique.find(c => c.value == nouveaute.categorie)'>
                  {{ ThematiqueSelects.ListThematique.find(c => c.value == nouveaute.categorie).categorie }}
                </template>
                <template v-else>
                  {{ nouveaute.categorie }}
                </template>
              </h6>
              <img
                v-if="nouveaute.hostname != 'plf'"
                :src="'https://www.weedoit.fr/tracking/partenaire/' + nouveaute.repertoire + '/profil-S.png'"
                @error="imageLoadError"
                width="150"
                height="79"
              />
              <img
                v-else
                :src="'https://weedoit-media.s3.eu-central-1.amazonaws.com/programme/' + nouveaute.repertoire + '/profil-S.png'"
                @error="imageLoadError"
                width="150"
                height="79"
              />
            </div>
            <div class="card-custom-avatar">
              <el-tooltip
                :content="nouveaute.prenom_manager +' '+nouveaute.nom_manager.toUpperCase()"
                effect="light"
                :open-delay="300"
                placement="top"
              >
                <template v-if="nouveaute.image_manager == null">
                  <img class="img-fluid" :src="'/img/'+TmPhoto[index]" />
                </template>
                <template v-else>
                  <img class="img-fluid" :src="'https://weedoit-media.s3.eu-central-1.amazonaws.com/'+nouveaute.image_manager" />
                </template>
              </el-tooltip>
            </div>
            <div class="card-body" style="overflow-y: auto">
              <h4 class="card-title">
                <template v-if="nouveaute.Titre.length > 15">
                  <!-- <el-tooltip
                    :content="Editer"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  > -->
                  {{ nouveaute.Titre.slice(0,15) }}...<br/> <br />
                  <!-- </el-tooltip> -->
                </template>
                <template v-else>
                  {{ nouveaute.Titre }}<br /><br />
                </template>
              </h4>
              <p class="card-text">
                <template
                  style="color:#000; font-size:14px"
                  v-if="nouveaute.campagne.includes('lead')"
                >{{ nouveaute.prix_editeur }} €/lead <br/></template>
                <template
                  style="color:#000; font-size:14px"
                  v-if="nouveaute.campagne.includes('affichage')"
                >{{ nouveaute.prix_editeur_cpc }} €/clic <br/></template>
                <template
                  style="color:#000; font-size:14px"
                  v-if="nouveaute.campagne.includes('cpm')"
                >{{ nouveaute.prix_editeur_cpm }} €/cpm <br/></template>
                <template
                  style="color:#000; font-size:14px"
                  v-if="nouveaute.campagne.includes('vente fixe')"
                >
                  {{ nouveaute.prix_editeur_cpa_fixe }} €/cpa  <br />
                </template>
                <template
                  style="color:#000; font-size:14px"
                  v-if="nouveaute.campagne.includes('sms')"
                >
                  {{ nouveaute.prix_editeur_sms }} €/sms  <br />
                </template>
                <template
                  style="color:#000; font-size:14px"
                  v-if="nouveaute.campagne.includes('pourcentage des ventes')"
                >
                  {{ nouveaute.prix_editeur_cpa }} %/cpa  <br />
                </template>
                <template
                  style="color:#000; font-size:14px"
                  v-if="nouveaute.campagne.includes('téléchargement')"
                >
                  {{ nouveaute.prix_editeur_cpd }} €/cpd  <br />
                </template>
                <!-- TM : {{ nouveaute.manager }} -->
                TM :
                {{ nouveaute.nom_manager.toUpperCase()}}
                {{nouveaute.prenom_manager}}
              </p>
            </div>
            <div class="card-footer" style="background: inherit; border-color: inherit;">
              <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                <label @click="handleEdit(nouveaute)" style="font-size: 0.75rem" class="btn btn-sm btn-primary btn-simple active" id="0">
                  <input type="radio" name="options" checked />
                  <span class="d-none d-sm-block d-md-block d-lg-block d-xl-block">{{ $t("buttons.BtnModify").toUpperCase() }}</span>
                  <span class="d-block d-sm-none">
                    <i class="tim-icons icon-pencil"></i>
                  </span>
                </label>
                <label @click="Visualiser(nouveaute)" style="font-size: 0.75rem" class="btn btn-sm btn-primary btn-simple" id="1">
                  <input type="radio" class="d-none d-sm-none" name="options" />
                  <span class="d-none d-sm-block d-md-block d-lg-block d-xl-block">{{ $t("buttons.BtnLearnMore").toUpperCase() }}</span>
                  <span class="d-block d-sm-none">
                    <i class="tim-icons icon-tv-2"></i>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </slide>
      </carousel-3d>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <card>
          <div class="col-md-12">
            <div class="row">
              <div class="col-lg-3 col-md-4 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary"
                    placeholder="MANAGER"
                    :disabled="$apollo.queries.listManager.loading"
                    v-model="ManagerSelects.Manager"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="option in ManagerSelects.ListManager"
                      class="select-primary"
                      :value="option.email_client+'SPLITE'+option.id_client"
                      :label="option.prenom_client +' '+ option.nom_client.toUpperCase()"
                      :key="option.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary"
                    :placeholder="$t('table.columns.Announcer').toUpperCase()"
                    :disabled="$apollo.queries.listAnnonceur.loading"
                    v-model="AnnonceurSelects.Annonceur"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="option in AnnonceurSelects.ListAnnonceur"
                      class="select-primary"
                      :value="option.id_client"
                      :label="option.societe_client.toUpperCase()"
                      :key="option.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary"
                    :placeholder="$t('table.columns.RemunerationMethod')"
                    :disabled="$apollo.queries.listModeRemuneration.loading"
                    v-model="ModeRemunerationSelects.ModeRemuneration"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="option in ModeRemunerationSelects.ListModeRemuneration"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary"
                    :placeholder="$t('inputs.Themes').toUpperCase()"
                    v-model="ThematiqueSelects.Thematique"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="option in ThematiqueSelects.ListThematique"
                      class="select-primary"
                      :value="option.value"
                      :label="option.categorie.toUpperCase()"
                      :key="option.id"
                    >
                    {{ option.categorie.toUpperCase() }}<i :class="option.icon+'  pull-right pt-2'"></i>
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary"
                    :placeholder="$t('inputs.DiffusionType').toUpperCase()"
                    v-model="TypeDiffusionSelects.TypeDiffusion"
                    clearable
                  >
                    <el-option
                      v-for="option in TypeDiffusionSelects.ListTypeDiffusion"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label.toUpperCase()"
                      :key="option.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary"
                    :placeholder="$t('table.columns.State').toUpperCase()"
                    v-model="StatSelects.Stat"
                    clearable
                  >
                    <el-option
                      v-for="option in StatSelects.ListStat"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label.toUpperCase()"
                      :key="option.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary"
                    placeholder="VISIBLE"
                    v-model="DisplaySelects.Display"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="option in DisplaySelects.ListDisplay"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary"
                    :placeholder="$t('inputs.Country').toUpperCase()"
                    v-model="PaysSelects.Pays"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="option in PaysSelects.ListPays"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label.toUpperCase()"
                      :key="option.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6">
                <base-input type="text" :placeholder="$t('table.columns.Keywords')" v-model="keyword"></base-input>
              </div>
            </div>
          </div>
          <div class="text-right">
            <base-button
              v-if="$apollo.queries.listAnnonces.loading"
              loading
              class="btn btn-just-icon btn-default mt-0 d-block"
            ></base-button>
            <button v-else class="btn btn-just-icon btn-default" @click="reset()">{{ $t("buttons.BtnReset").toUpperCase() }}</button>
          </div>
        </card>
        <card v-if="!$apollo.queries.listManager.loading">
          <div>
            <div class="row">
              <div class="col-xl-1 col-6">
                <base-input>
                  <el-select
                    class="select-primary pagination-select"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-xl-11 col-6">
                <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
              </div>
            </div>
            <el-table :data="filterlistAnnonces">
              <el-table-column
                prop="Titre"
                :label="$t('table.columns.Campagne')"
                class="table-striped"
                min-width="270"
                sortable>
                <template scope="scope">
                  <div class="prog mr-2 float-left">
                    <img
                      v-if="scope.row.hostname != 'plf'"
                      :src="'https://www.weedoit.fr/tracking/partenaire/' + scope.row.repertoire + '/profil-S.png'"
                      @error="imageLoadError"
                      @click="Visualiser(scope.row)"
                      style="cursor: pointer;"
                    />
                    <img
                      v-else
                      :src="'https://weedoit-media.s3.eu-central-1.amazonaws.com/programme/' + scope.row.repertoire + '/profil-S.png'"
                      @error="imageLoadError"
                      @click="Visualiser(scope.row)"
                      style="cursor: pointer;"
                    />
                  </div>
                  <div class="pt-3">

                    <template v-if="scope.row.Titre.length <= 20">
                      {{ scope.row.Titre.toUpperCase() }}
                    </template>
                    <template v-else>
                      <el-tooltip
                        :content="scope.row.Titre"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                        <label>
                          {{ scope.row.Titre.slice(0,20).toUpperCase() }}...
                        </label>
                      </el-tooltip>
                    </template>

                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="societe_client"
                :label="$t('table.columns.Announcer')"
                class="table-striped"
                min-width="150"
                sortable
                show-overflow-tooltip
                >
                <template scope="scope">
                  {{ scope.row.societe_client.toUpperCase() }}
                </template>
              </el-table-column>
              <el-table-column
                prop="prix_editeur"
                label="Rémunération"
                class="table-striped"
                min-width="160"
                sortable
                :sort-method="CustomSort_price">
                <template scope="scope">
                  <!-- <label
                    style="font-size:14px"
                    v-if="scope.row.campagne == 'lead'"
                  >{{ scope.row.prix_editeur + ' €/lead' }} </label>
                  <label
                    style="font-size:14px"
                    v-else-if="scope.row.campagne == 'affichage'"
                  >{{ scope.row.prix_editeur + ' €/clic' }}</label>
                  <label
                    style="font-size:14px"
                    v-else-if="scope.row.campagne == 'cpm'"
                  >{{ scope.row.prix_editeur + ' €/CPM' }} </label>
                  <label style="font-size:14px" v-else>{{ scope.row.prix_editeur +' €/CPA' }} </label> -->

                  <template
                    style="color:#000; font-size:14px"
                    v-if="scope.row.campagne.includes('lead')"
                  >{{ scope.row.prix_editeur }} €/lead </template>
                  <template
                    style="color:#000; font-size:14px"
                    v-else-if="scope.row.campagne.includes('affichage')"
                  >{{ scope.row.prix_editeur_cpc }} €/clic </template>
                  <template
                    style="color:#000; font-size:14px"
                    v-else-if="scope.row.campagne.includes('cpm')"
                  >{{ scope.row.prix_editeur_cpm }} €/cpm </template>
                  <template
                    style="color:#000; font-size:14px"
                    v-else-if="scope.row.campagne.includes('vente fixe')"
                  >
                    {{ scope.row.prix_editeur_cpa_fixe }} €/cpa
                  </template>
                  <template
                    style="color:#000; font-size:14px"
                    v-else-if="scope.row.campagne.includes('sms')"
                  >
                    {{ scope.row.prix_editeur_sms }} €/sms
                  </template>
                  <template
                    style="color:#000; font-size:14px"
                    v-else-if="scope.row.campagne.includes('pourcentage des ventes')"
                  >
                    {{ scope.row.prix_editeur_cpa }} %/cpa
                  </template>
                  <template
                    style="color:#000; font-size:14px"
                    v-else
                  >
                    {{ scope.row.prix_editeur_cpd }} €/cpd
                  </template>

                </template>
              </el-table-column>
              <el-table-column
                prop="categorie"
                :label="$t('inputs.Themes')"
                class="table-striped"
                min-width="150"
                sortable
                show-overflow-tooltip>
                <template scope="scope">
                  <template v-if='ThematiqueSelects.ListThematique.find(c => c.value == scope.row.categorie)'>
                    <el-tooltip
                      :content="ThematiqueSelects.ListThematique.find(c => c.value == scope.row.categorie).categorie"
                      effect="light"
                      :open-delay="300"
                      placement="top">
                        <i :class="ThematiqueSelects.ListThematique.find(c => c.value == scope.row.categorie).icon"></i>
                    </el-tooltip>
                  </template>
                  <template v-else>
                    {{ scope.row.categorie }}
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                prop="manager"
                label="Manager"
                min-width="260"
                class="table-striped"
                sortable>
                <template scope="scope">
                    <template v-if="scope.row.image_manager == null">
                      <div class="tm_photo mr-2 float-left">
                        <img :src="'/img/'+ TmPhoto[(scope.$index < 7)? scope.$index : 2]" alt="photo" />
                      </div>
                    </template>
                    <template v-else>
                      <div class="tm_photo mr-2 float-left">
                        <img :src="'https://weedoit-media.s3.eu-central-1.amazonaws.com/'+scope.row.image_manager" alt="photo"/>
                      </div>
                    </template>
                  <div class="pt-1">{{ scope.row.manager }}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop label="Action"
                class="table-striped"
                min-width="150">
                <template scope="scope">
                  <el-tooltip
                    :content="$t('buttons.BtnModify').toUpperCase()"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  >
                    <base-button
                      @click.native="handleEdit(scope.row)"
                      class="edit btn-link"
                      type="info"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </base-button>
                  </el-tooltip>
                  <el-tooltip
                      :content="$t('buttons.BtnView').toUpperCase()"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="Visualiser(scope.row)"
                        class="like btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-tv-2"></i>
                      </base-button>
                    </el-tooltip>
                  <!-- <el-tooltip
                    content="Dupliquer"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  >
                    <base-button
                      @click.native="DupliquerProgram(scope.row)"
                      class="like btn-link"
                      type="info"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-single-copy-04"></i>
                    </base-button>
                  </el-tooltip> -->
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p
                class="card-category"
              >{{ $t('labels.Datatable.Showing') }} {{ from + 1 }} {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}</p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            ></base-pagination>
          </div>
        </card>
      </div>
    </div>
    <!-- Edit Program Modal -->
    <modal
      :show.sync="EditProgrmShow"
      body-classes="p-0"
      class="modal-listbase">
      <card type="secondary"
        header-classes="bg-white pb-6"
        body-classes="px-lg-6 py-lg-6"
        class="border-0 mb-0">
        <div class="form-row">
          <base-input :label="$t('table.columns.Announcer') + ' *'" class="col-md-6" :error="getError($t('table.columns.Announcer'))">
            <el-select
              class="select-primary"
              :placeholder="$t('table.columns.Announcer').toUpperCase()"
              :disabled="$apollo.queries.listAnnonceur.loading"
              v-model="AnnonceurSelects.AnnonceurEdit"
              :name="$t('table.columns.Announcer')"
              v-validate="'required'"
              filterable
              clearable
            >
              <el-option
                v-for="option in AnnonceurSelects.ListAnnonceur"
                class="select-primary"
                :value="option.id_client"
                :label="option.societe_client.toUpperCase()"
                :key="option.id"
              ></el-option>
            </el-select>
          </base-input>
          <base-input :label="$t('table.columns.Title') + ' *'" :placeholder="$t('table.columns.Title').toUpperCase()"
            class="col-md-6"
            v-validate="'required'"
            :error="getError($t('table.columns.Title'))"
            :name="$t('table.columns.Title')"
            v-model="Titre"
            >
          </base-input>
        </div>
        <div class="form-row">
          <base-input :label="$t('inputs.TypeRemuneration').toUpperCase() + ' *'" class="col-md-6" :error="getError($t('inputs.TypeRemuneration'))">
            <el-select multiple class="select-primary"
                    :placeholder="$t('inputs.TypeRemuneration').toUpperCase()"
                    :disabled="$apollo.queries.listModeRemuneration.loading"
                    v-model="ModeRemunerationSelects.ModeRemunerationEdit"
                    :name="$t('inputs.TypeRemuneration')"
                    v-validate="'required'">
              <el-option v-for="option in ModeRemunerationSelects.ListModeRemuneration"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label">
              </el-option>
            </el-select>
          </base-input>
          <base-input :label="$t('inputs.Minimumstatus').toUpperCase() +' *'" class="col-md-6" :error="getError($t('inputs.Minimumstatus'))">
            <el-select class="select-primary"
                    :placeholder="$t('inputs.Minimumstatus').toUpperCase()"
                    :name="$t('inputs.Minimumstatus')"
                    v-validate="'required'"
                    v-model="StatutSelects.Statut">
              <el-option v-for="option in StatutSelects.StatutList"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label">
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="form-row" v-if="ModeRemunerationSelects.ModeRemunerationEdit.includes('affichage')">
          <base-input
            v-model="prix_annonceur_cpc"
            label="RÉMUNÉRATION CPL ANNONCEUR (CPC)"
            class="col-md-6"
            type="number"
            placeholder="RÉMUNÉRATION CPL ANNONCEUR (CPC)">
          </base-input>
          <base-input
            v-model="prix_editeur_cpc"
            label="RÉMUNÉRATION CPL ÉDITEUR (CPC)"
            class="col-md-6"
            type="number"
            placeholder="RÉMUNÉRATION CPL ÉDITEUR (CPC)">
          </base-input>
        </div>
        <div class="form-row" v-if="ModeRemunerationSelects.ModeRemunerationEdit.includes('cpm')">
          <base-input
            v-model="prix_annonceur_cpm"
            label="RÉMUNÉRATION CPL ANNONCEUR (CPM)"
            class="col-md-6"
            type="number"
            placeholder="RÉMUNÉRATION CPL ANNONCEUR (CPM)">
          </base-input>
          <base-input
            v-model="prix_editeur_cpm"
            label="RÉMUNÉRATION CPL ÉDITEUR (CPM)"
            class="col-md-6"
            type="number" placeholder="RÉMUNÉRATION CPL ÉDITEUR (CPM)">
          </base-input>
        </div>
        <div class="form-row" v-if="ModeRemunerationSelects.ModeRemunerationEdit.includes('enrichissement')">
          <base-input
            v-model="prix_annonceur_enrichissement"
            label="RÉMUNÉRATION CPL ANNONCEUR (ENRICHISSEMENT)"
            class="col-md-6"
            type="number"
            placeholder="RÉMUNÉRATION CPL ANNONCEUR (ENRICHISSEMENT)">
          </base-input>
          <base-input
            v-model="prix_editeur_enrichissement"
            label="RÉMUNÉRATION CPL ÉDITEUR (ENRICHISSEMENT)"
            class="col-md-6"
            type="number"
            placeholder="RÉMUNÉRATION CPL ÉDITEUR (ENRICHISSEMENT)">
          </base-input>
        </div>
        <div class="form-row" v-if="ModeRemunerationSelects.ModeRemunerationEdit.includes('lead')">
          <base-input
            v-model="prix_annonceur"
            label="RÉMUNÉRATION CPL ANNONCEUR (CPL)"
            class="col-md-6"
            type="number"
            placeholder="RÉMUNÉRATION CPL ANNONCEUR (CPL)">
          </base-input>
          <base-input
            v-model="prix_editeur"
            label="RÉMUNÉRATION CPL ÉDITEUR (CPL)"
            class="col-md-6"
            type="number"
            placeholder="RÉMUNÉRATION CPL ÉDITEUR (CPL)">
          </base-input>
        </div>
        <div class="form-row" v-if="ModeRemunerationSelects.ModeRemunerationEdit.includes('pourcentage des ventes')">
          <base-input
            v-model="prix_annonceur_cpa"
            label="RÉMUNÉRATION CPL ANNONCEUR (CPA)"
            class="col-md-6"
            type="number"
            placeholder="RÉMUNÉRATION CPL ANNONCEUR (CPA)">
          </base-input>
          <base-input
            v-model="prix_editeur_cpa"
            label="RÉMUNÉRATION CPL ÉDITEUR (CPA)"
            class="col-md-6"
            type="number"
            placeholder="RÉMUNÉRATION CPL ÉDITEUR (CPA)">
          </base-input>
        </div>
        <div class="form-row" v-if="ModeRemunerationSelects.ModeRemunerationEdit.includes('sms')">
          <base-input
            v-model="prix_annonceur_sms"
            label="RÉMUNÉRATION CPL ANNONCEUR (SMS)"
            class="col-md-6"
            type="number"
            placeholder="RÉMUNÉRATION CPL ANNONCEUR (SMS)">
          </base-input>
          <base-input
            v-model="prix_editeur_sms"
            label="RÉMUNÉRATION CPL ÉDITEUR (SMS)"
            class="col-md-6"
            type="number"
            placeholder="RÉMUNÉRATION CPL ÉDITEUR (SMS)">
          </base-input>
        </div>
        <div class="form-row" v-if="ModeRemunerationSelects.ModeRemunerationEdit.includes('téléchargement')">
          <base-input
            v-model="prix_annonceur_cpd"
            label="RÉMUNÉRATION CPL ANNONCEUR (CPD)"
            class="col-md-6"
            type="number"
            placeholder="RÉMUNÉRATION CPL ANNONCEUR (CPD)">
          </base-input>
          <base-input
            v-model="prix_editeur_cpd"
            label="RÉMUNÉRATION CPL ÉDITEUR (CPD)"
            class="col-md-6"
            type="number"
            placeholder="RÉMUNÉRATION CPL ÉDITEUR (CPD)">
          </base-input>
        </div>
        <div class="form-row" v-if="ModeRemunerationSelects.ModeRemunerationEdit.includes('vente fixe')">
          <base-input
            v-model="prix_annonceur_cpa_fixe"
            label="RÉMUNÉRATION CPL ANNONCEUR (CPA FIXE)"
            class="col-md-6"
            type="number"
            placeholder="RÉMUNÉRATION CPL ANNONCEUR (CPA FIXE)">
          </base-input>
          <base-input
            v-model="prix_editeur_cpa_fixe"
            label="RÉMUNÉRATION CPL ÉDITEUR (CPA FIXE)"
            class="col-md-6"
            type="number"
            placeholder="RÉMUNÉRATION CPL ÉDITEUR (CPA FIXE)">
          </base-input>
        </div>
        <div class="form-row">
          <base-input label="Format" class="col-md-6">
            <base-checkbox inline class="mb-3" v-model="FormatCheckboxes.Mailing">
              MAILING
            </base-checkbox>
            <base-checkbox inline class="mb-3" v-model="FormatCheckboxes.Banniere">
              BANNIÈRE
            </base-checkbox>
            <base-checkbox inline class="mb-3" v-model="FormatCheckboxes.Flux">
              FLUX XML
            </base-checkbox>
            <base-checkbox inline class="mb-3" v-model="FormatCheckboxes.Sms">
              SMS
            </base-checkbox>
          </base-input>
          <base-input :label="$t('table.columns.State').toUpperCase() + ' *'" class="col-md-6" :error="getError($t('table.columns.State').toLowerCase())">
            <el-select class="select-primary"
                    :placeholder="$t('table.columns.State')"
                    :name="$t('table.columns.State').toLowerCase()"
                    v-validate="'required'"
                    v-model="EtatSelects.Etat">
              <el-option v-for="option in EtatSelects.EtatList"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label">
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="form-row">
          <card>
          <base-input label="Autorisation" class="col-md-12">
            <base-checkbox inline class="mb-3" v-model="tableau.emaling">
              E-mailing
            </base-checkbox>
            <base-checkbox inline class="mb-3" v-model="tableau.Banniere">
              Bannière
            </base-checkbox>
            <base-checkbox inline class="mb-3" v-model="tableau.Pop_under">
              Pop-under
            </base-checkbox>
            <base-checkbox inline class="mb-3" v-model="tableau.Site_under">
              Site-under
            </base-checkbox>
            <base-checkbox inline class="mb-3" v-model="tableau.Incentive_clic">
              Incentive clic
            </base-checkbox>
            <base-checkbox inline class="mb-3" v-model="tableau.Incentive_remplissage">
              Incentive remplissage
            </base-checkbox>
            <base-checkbox inline class="mb-3" v-model="tableau.Achat_mots_cles">
              Achat de mots clès
            </base-checkbox>
          </base-input>
          </card>
        </div>
        <div class="form-row">
          <base-input
            v-model="URL_Flux_XML"
            label="URL Flux XML"
            class="col-md-6"
            type="text"
            placeholder="URL FLUX XML">
          </base-input>
          <base-input
            v-model="Cle_items_XML"
            label="Clé items (Flux XML)"
            class="col-md-6"
            type="text"
            placeholder="CLÉ ITEMS (FLUX XML)">
          </base-input>
        </div>
        <div class="form-row">
          <base-input
            v-model="Description"
            label="Description"
            class="col-md-6"
            type="text"
            placeholder="DESCRIPTION">
          </base-input>
          <base-input label="MANAGER" class="col-md-6">
            <el-select
              class="select-primary"
              placeholder="MANAGER"
              :disabled="$apollo.queries.listManager.loading"
              v-model="ManagerSelects.ManagerEdit"
              filterable
              clearable
            >
              <el-option
                v-for="option in ManagerSelects.ListManager"
                class="select-primary"
                :value="option.email_client+'SPLITE'+option.id_client"
                :label="option.prenom_client +' '+ option.nom_client.toUpperCase()"
                :key="option.id_client"
              ></el-option>
            </el-select>
          </base-input>
        </div>
        <div class="form-row">
          <base-input :label="$t('table.columns.Categorie').toUpperCase() + ' *'" class="col-md-6" :error="getError($t('table.columns.Categorie'))">
            <el-select class="select-primary"
                    :placeholder="$t('table.columns.Categorie').toUpperCase()"
                    filterable
                    :name="$t('table.columns.Categorie')"
                    v-validate="'required'"
                    clearable
                    v-model="ThematiqueSelects.ThematiqueEdit">
              <el-option v-for="option in ThematiqueSelects.ListThematique"
                      class="select-primary"
                      :value="option.value"
                      :label="option.categorie.toUpperCase()"
                      :key="option.name">
              </el-option>
            </el-select>
          </base-input>
          <base-input :label="$t('inputs.Language').toUpperCase() + ' *'" class="col-md-6" :error="getError($t('inputs.Language'))">
            <el-select class="select-primary"
                    :placeholder="$t('inputs.Language').toUpperCase()"
                    filterable
                    :name="$t('inputs.Language')"
                    v-validate="'required'"
                    clearable
                    v-model="LangueSelects.Langue">
              <el-option v-for="option in LangueSelects.LangueList"
                      class="select-primary"
                      :value="option.iso2"
                      :label="option.name.toUpperCase()"
                      :key="option.iso2">
              </el-option>
            </el-select>
          </base-input>
        </div>
        <center>
          <base-input label="Image profil">
            <image-upload
              @change="onImageChange"
              type="avatar">
            </image-upload>
          </base-input>
        </center>
        <div class="form-row">
          <base-input :label="$t('inputs.SecureTracking')" class="col-md-6">
            <el-select class="select-primary"
                    :placeholder="$t('inputs.SecureTracking')"
                    v-model="SecureTrackingSelects.SecureTracking">
              <el-option v-for="option in SecureTrackingSelects.SecureTrackingList"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label">
              </el-option>
            </el-select>
          </base-input>
          <base-input label="CPC dynamique" class="col-md-6">
            <el-select class="select-primary"
                    placeholder="CPC DYNAMIQUE"
                    v-model="CPCDynamiqueSelects.CPCDynamique">
              <el-option v-for="option in CPCDynamiqueSelects.CPCDynamiqueList"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label">
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="form-row">
          <base-input label="VISIBLE" class="col-md-6">
            <el-select class="select-primary"
                    placeholder="VISIBLE"
                    v-model="VisibleSelects.Visible">
              <el-option v-for="option in VisibleSelects.VisibleList"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label">
              </el-option>
            </el-select>
          </base-input>
          <base-input label="Retargeting" class="col-md-6">
            <base-checkbox class="mb-3" v-model="Retargeting">
              RETARGETING
            </base-checkbox>
          </base-input>
        </div>
        <div class="form-row mb-2">
          <base-input label="CATALOGUE" class="col-md-6">
            <el-select class="select-primary"
              placeholder="CATALOGUE"
              v-model="CatalogueSelects.Catalogue"
            >
              <el-option v-for="option in CatalogueSelects.CatalogueList"
                class="select-primary"
                :value="option.value"
                :label="option.label"
                :key="option.label"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <center>
          <base-button
            type="info"
            @click="UpdateProgram"
            >{{ $t("buttons.BtnModify").toUpperCase() }}
          </base-button>
        </center>
      </card>
    </modal>
    <!-- Edit Program Modal -->
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { Table, TableColumn } from "element-ui";
import gql from "graphql-tag";
import { BaseProgress } from "@/components";
import { BasePagination } from "src/components";
import { Carousel3d, Slide } from "vue-carousel-3d";
import {Modal} from 'src/components'
import {ImageUpload} from 'src/components'

export default {
  watch: {
    'StatSelects.Stat'(value) {
      if (value === 'supprime') {
        this.$apollo.queries.listAnnonces.refetch({
          id_client: null,
          droit: "ad",
          langue: "FR",
          state_Delete: true
        })
      }
      else {
        this.$apollo.queries.listAnnonces.refetch({
          id_client: null,
          droit: "ad",
          langue: "FR",
          state_Delete: false
        })
      }
    }
  },
  apollo: {
    nouveautes: {
      query: require("src/graphql/NewCampagnes.gql"),
      variables: {
        role: "ad"
      },
      fetchPolicy: 'network-only',
      result({ data, loader, networkStatus }) {}
    },
    listManager: {
      query: gql`
        query {
          listManager(
            bloque: "non"
            etat: "valide"
            droit: ["ma", "tem"]
            orderBy: [
              { field: "prenom_client", order: ASC }
              { field: "nom_client", order: ASC }
            ]
          ) {
            id_client
            nom_client
            prenom_client
            email_client
          }
        }
      `,
      result({ data, loader, networkStatus }) {
        this.ManagerSelects.ListManager = data.listManager;
        let manager = this.ManagerSelects.ListManager.find(m => parseInt(m.id_client) === JSON.parse(localStorage.getItem('utilisateur')).id_clien_myaffil)
        if (manager) {
          this.ManagerSelects.Manager = manager.email_client+'SPLITE'+manager.id_client
        }
      }
    },
    listPays: {
      query: gql`
        query {
          listPays(iso2:""){
            iso2
            name
          }
        }
      `,
      result({ data, loader, networkStatus }) {
        this.LangueSelects.LangueList = data.listPays
      }
    },
    listAnnonceur: {
      query: gql`
        query {
          listAnnonceur {
            id_client
            email_client
            societe_client
          }
        }
      `,
      result({ data, loader, networkStatus }) {
        this.AnnonceurSelects.ListAnnonceur = data.listAnnonceur;
      }
    },
    listModeRemuneration: {
      query: gql`
        query {
          listModeRemuneration {
            campagne
          }
        }
      `,
      result({ data, loader, networkStatus }) {}
    },
    listAnnonces: {
      query: require("src/graphql/ListprogramWeedoPerf.gql"),
      fetchPolicy: 'network-only',
      update: data => data.filterAnnonces,
      variables() {
        return {
          id_client: null,
          droit: "ad",
          langue: "FR",
          state_Delete: false
        }
      },
    }
  },
  components: {
    BasePagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    BaseProgress,
    Carousel3d,
    Slide,
    Modal,
    ImageUpload
  },
  data() {
    return {
      TmPhoto:["emilyz.jpg","james.jpg","jana.jpg","lora.jpg","mike.jpg","robi.jpg","tania.jpg"],
      EditProgrmShow: null,
      nouveautes: null,
      listAnnonces: null,
      id_programme_selected: null,
      //             VARIABLE EDIT PROGRAM                //
      Titre: "",
      prix_annonceur_cpc: "",
      prix_editeur_cpc: "",
      prix_annonceur_cpm: "",
      prix_editeur_cpm: "",
      prix_annonceur_enrichissement: "",
      prix_editeur_enrichissement: "",
      prix_annonceur: "",
      prix_editeur: "",
      prix_annonceur_cpa: "",
      prix_editeur_cpa: "",
      prix_annonceur_sms: "",
      prix_editeur_sms: "",
      prix_annonceur_cpd: "",
      prix_editeur_cpd: "",
      prix_annonceur_cpa_fixe: "",
      prix_editeur_cpa_fixe: "",
      URL_Flux_XML: "",
      Cle_items_XML: "",
      Description: "",
      Retargeting: false,
      //             VARIABLE EDIT PROGRAM                //
      keyword: "",
      SecureTrackingSelects: {
        SecureTracking: "",
        SecureTrackingList: [
          {value: true,label: this.$t("labels.Yes")},
          {value: false,label: this.$t("labels.No")}
        ]
      },
      CPCDynamiqueSelects: {
        CPCDynamique: "",
        CPCDynamiqueList: [
          {value: "0",label: this.$t("labels.No")},
          {value: "livik",label: "LIVIK"}
        ]
      },
      ManagerSelects: {
        ManagerEdit: "",
        Manager: "",
        ListManager: []
      },
      AnnonceurSelects: {
        AnnonceurEdit: "",
        Annonceur: "",
        ListAnnonceur: []
      },
      LangueSelects: {
        Langue: '',
        LangueList: []
      },
      EtatSelects: {
        Etat: '',
        EtatList: this.$t("Database.Stat")
      },
      ModeRemunerationSelects: {
        ModeRemunerationEdit: "",
        ModeRemuneration: "",
        ListModeRemuneration: [
          { value: "affichage", label: "CPC" },
          { value: "cpm", label: "CPM" },
          { value: "enrichissement", label: "ENRICHISSEMENT" },
          { value: "lead", label: "CPL" },
          { value: "pourcentage des ventes", label: "CPA" },
          { value: "sms", label: "SMS" },
          { value: "téléchargement", label: "CPD" },
          { value: "vente fixe", label: "CPA Fixe" }
        ]
      },
      ThematiqueSelects: {
        ThematiqueEdit: "",
        Thematique: "",
        ListThematique: this.$t("Database.Thematiques")
      },
      TypeDiffusionSelects: {
        TypeDiffusion: "",
        ListTypeDiffusion: this.$t("Database.TypeDiffusion")
      },
      StatutSelects: {
        Statut: '',
        StatutList: [
          {value: 'AUCUN' , label: 'AUCUN'},
          {value: 'BRONZE' , label: 'BRONZE'},
          {value: 'SILVER' , label: 'SILVER'},
          {value: 'GOLD' , label: 'GOLD'}
        ]
      },
      StatSelects: {
        Stat: '',
        ListStat: this.$t("Database.Stat")
      },
      DisplaySelects: {
        Display: '',
        ListDisplay: [
          {value: "true" , label: this.$t("labels.Yes")},
          {value: "false" , label: this.$t("labels.No")}
        ]
      },
      PaysSelects: {
        Pays: '',
        ListPays: [
          {value: "de", label: "Allemagne"},
          {value: "ads", label: "Afrique du Sud"},
          {value: "aus", label: "Australie"},
          {value: "be", label: "Belgique"},
          {value: "br", label: "Brésil"},
          {value: "da", label: "Danemark"},
          {value: "uae", label: "Émirats arabes unis"},
          {value: "es", label: "Espagne"},
          {value: "us", label: "États-Unis"},
          {value: "fi", label: "Finlande"},
          {value: "fr", label: "france"},
          {value: "it", label: "Italie"},
          {value: "lux", label: "Luxembourg"},
          {value: "no", label: "Norvège"},
          {value: "nz", label: "Nouvelle-Zélande (New Zealand)"},
          {value: "nl", label: "Pays-Bas"},
          {value: "po", label: "Pologne"},
          {value: "pt", label: "Portugal"},
          {value: "uk", label: "Royaume-Uni (United Kingdom)"},
          {value: "ch", label: "Suisse"},
          {value: "se", label: "Suède"}
        ]
      },
      FormatCheckboxes: {
        Mailing: true,
        Banniere : false,
        Flux: false,
        Sms: false
      },
      tableau: {
        emaling: false,
        Banniere: false,
        Pop_under: false,
        Site_under: false,
        Incentive_clic: false,
        Incentive_remplissage: false,
        Achat_mots_cles: false,
      },
      VisibleSelects: {
        Visible: '',
        VisibleList: [
          {value: true , label: this.$t("labels.Yes")},
          {value: false , label: this.$t("labels.No")}
        ]
      },
      CatalogueSelects: {
        Catalogue: '',
        CatalogueList: [
          {value: true , label: this.$t("labels.Yes")},
          {value: false , label: this.$t("labels.No")}
        ]
      },
      images: {
        profile: null
      },
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 25, 100, 250, 500],
        total: 0
      },
      total: 0
    };
  },
  methods: {
    CustomSort_price(a, b) {
      let price1 = 0
      let price2 = 0
      if (a.campagne.includes('lead')) {
        price1 = a.prix_editeur
      }
      else if (a.campagne.includes('affichage')) {
        price1 = a.prix_editeur_cpc
      }
      else if (a.campagne.includes('cpm')) {
        price1 = a.prix_editeur_cpm
      }
      else if (a.campagne.includes('cpa')) {
        price1 = a.prix_editeur_cpa
      }
      else if (a.campagne.includes('sms')) {
        price1 = a.prix_editeur_sms
      }
      else {
        price1 = a.prix_editeur_cpd
      }

      if (b.campagne.includes('lead')) {
        price2 = b.prix_editeur
      }
      else if (b.campagne.includes('affichage')) {
        price2 = b.prix_editeur_cpc
      }
      else if (b.campagne.includes('cpm')) {
        price2 = b.prix_editeur_cpm
      }
      else if (b.campagne.includes('cpa')) {
        price2 = b.prix_editeur_cpa
      }
      else if (b.campagne.includes('sms')) {
        price2 = b.prix_editeur_sms
      }
      else {
        price2 = b.prix_editeur_cpd
      }

      return price1 - price2
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    onImageChange(file) {
      this.images.profile = file;
    },
    imageLoadError(event) {
      event.target.src =
        "/img/missingprofile.jpg";
    },
    Visualiser(row) {
      localStorage.setItem('ProgrammeVisualiser', JSON.stringify(row))
      this.$router.push({name: "Visualiser programme My Affil Admin"})
    },
    reset() {
      this.ManagerSelects.Manager = "";
      this.AnnonceurSelects.Annonceur = "";
      this.ModeRemunerationSelects.ModeRemuneration = "";
      this.ThematiqueSelects.Thematique = "";
      this.TypeDiffusionSelects.TypeDiffusion = "";
      this.keyword = "";
      this.StatSelects.Stat = "";
    },
    DupliquerProgram(program) {
      localStorage.setItem('DupliqueProgram', JSON.stringify(program));
      this.$router.push({ name: "Ajouter un Programme" })
    },
    handleEdit(row) {
      this.id_programme_selected = row.id_programme
      this.EditProgrmShow = true
      this.Titre = row.Titre
      this.AnnonceurSelects.AnnonceurEdit = row.id_client + ""
      this.ModeRemunerationSelects.ModeRemunerationEdit = row.campagne.split(",")
      this.prix_annonceur_cpc = row.prix_annonceur_cpc
      this.prix_editeur_cpc = row.prix_editeur_cpc
      this.prix_annonceur_cpm = row.prix_annonceur_cpm
      this.prix_editeur_cpm = row.prix_editeur_cpm
      this.prix_annonceur_enrichissement = row.prix_annonceur_enrichissement
      this.prix_editeur_enrichissement = row.prix_editeur_enrichissement
      this.prix_annonceur = row.prix_annonceur
      this.prix_editeur = row.prix_editeur
      this.prix_annonceur_cpa = row.prix_annonceur_cpa
      this.prix_editeur_cpa = row.prix_editeur_cpa
      this.prix_annonceur_sms = row.prix_annonceur_sms
      this.prix_editeur_sms = row.prix_editeur_sms
      this.prix_annonceur_cpd = row.prix_annonceur_cpd
      this.prix_editeur_cpd = row.prix_editeur_cpd
      this.prix_annonceur_cpa_fixe = row.prix_annonceur_cpa_fixe
      this.prix_editeur_cpa_fixe = row.prix_editeur_cpa_fixe
      this.StatutSelects.Statut = row.statut
      this.EtatSelects.Etat = row.etat
      this.Description = row.description
      this.ThematiqueSelects.ThematiqueEdit = row.categorie
      this.LangueSelects.Langue = row.langue
      this.VisibleSelects.Visible = row.display
      this.ManagerSelects.ListManager.forEach(manager => {
        if (row.manager == manager.email_client)
          this.ManagerSelects.ManagerEdit = manager.email_client+"SPLITE"+manager.id_client
      });
      this.Retargeting = (row.retargeting==0)?false:true
      this.SecureTrackingSelects.SecureTracking = row.tracking_securise
      this.CPCDynamiqueSelects.CPCDynamique = row.cpc_dyn
      // FORMAT FIELD
      this.FormatCheckboxes.Mailing = row.format.includes("mailing")
      this.FormatCheckboxes.Banniere = row.format.includes("bannière")
      this.FormatCheckboxes.Flux = row.format.includes("flux xml")
      this.FormatCheckboxes.Sms = row.format.includes("sms")
      // AUTORISE FIELD
      this.tableau.emaling = row.autorise.includes("E-mailing")
      this.tableau.Banniere = row.autorise.includes("bannière")
      this.tableau.Pop_under = row.autorise.includes("pop-under")
      this.tableau.Site_under = row.autorise.includes("site-under")
      this.tableau.Incentive_clic = row.autorise.includes("Incentive au clic")
      this.tableau.Incentive_remplissage = row.autorise.includes("incentive au remplissage")
      this.tableau.Achat_mots_cles = row.autorise.includes("achat de mots cles")

      this.CatalogueSelects.Catalogue = row.catalogue
    },
    async UpdateProgram(){


      let validate
      await this.$validator.validateAll().then(isValid => {
        validate = isValid
      })
      if (!validate)
        return

      let Compagne = this.ModeRemunerationSelects.ModeRemunerationEdit.join(",")
      let autorise = ''
      let no_autorise = ''
      let format = ''
      if (this.FormatCheckboxes.Mailing)
        format += 'mailing,'
      if (this.FormatCheckboxes.Banniere)
        format += 'bannière,'
      if (this.FormatCheckboxes.Flux)
        format += 'flux xml,'
      if (this.FormatCheckboxes.Sms)
        format += 'sms,'

      if(this.tableau.emaling)
        autorise += 'E-mailing,'
      else
        no_autorise += 'E-mailing,'

      if(this.tableau.Banniere)
        autorise += 'bannière,'
      else
        no_autorise += 'bannière,'

      if(this.tableau.Pop_under)
        autorise += 'pop-under,'
      else
        no_autorise += 'pop-under,'

      if(this.tableau.Site_under)
        autorise += 'site-under,'
      else
        no_autorise += 'site-under,'

      if(this.tableau.Incentive_clic)
        autorise += 'Incentive au clic,'
      else
        no_autorise += 'Incentive au clic,'

      if(this.tableau.Incentive_remplissage)
        autorise += 'incentive au remplissage,'
      else
        no_autorise += 'incentive au remplissage,'

      if(this.tableau.Achat_mots_cles)
        autorise += 'achat de mots cles,'
      else
        no_autorise += 'achat de mots cles,'
      this.$apollo.mutate({
        mutation: gql`mutation (
            $id_programme: ID!
            $file: Upload,
            $id_client: Int!,
            $Titre: String!,
            $campagne: String!,
            $format: String,
            $categorie: String!,
            $langue: String!,
            $etat: String!,
            $manager: String!,
            $prix_annonceur: Float,
            $prix_editeur: Float,
            $prix_annonceur_cpc: Float,
            $prix_editeur_cpc: Float,
            $prix_annonceur_cpm: Float,
            $prix_editeur_cpm: Float,
            $prix_annonceur_cpa: Float,
            $prix_editeur_cpa: Float,
            $prix_annonceur_sms: Float,
            $prix_editeur_sms: Float,
            $prix_annonceur_cpd: Float,
            $prix_editeur_cpd: Float,
            $prix_annonceur_cpa_fixe: Float,
            $prix_editeur_cpa_fixe: Float,
            $prix_annonceur_enrichissement: Float,
            $prix_editeur_enrichissement: Float,
            $description: String,
            $provenance: String,
            $statut: String!,
            $id_manager: Int,
            $url_flux: String,
            $item_childs: String,
            $autorise: String,
            $no_autorise: String,
            $retargeting: Int,
            $display: Boolean,
            $type: Boolean,
            $cpc_dyn: String,
            $tracking_securise: Boolean,
            $catalogue: Boolean
          ) {
          updateProgramme(
                            id_programme: $id_programme,
                            file: $file,
                            id_client: $id_client,
                            Titre: $Titre,
                            campagne: $campagne,
                            format: $format,
                            categorie: $categorie,
                            langue: $langue,
                            etat: $etat,
                            manager: $manager,
                            prix_editeur: $prix_editeur,
                            prix_annonceur: $prix_annonceur,
                            prix_annonceur_cpc: $prix_annonceur_cpc,
                            prix_editeur_cpc: $prix_editeur_cpc,
                            prix_annonceur_cpm: $prix_annonceur_cpm,
                            prix_editeur_cpm: $prix_editeur_cpm,
                            prix_annonceur_cpa: $prix_annonceur_cpa,
                            prix_editeur_cpa: $prix_editeur_cpa,
                            prix_annonceur_sms: $prix_annonceur_sms,
                            prix_editeur_sms: $prix_editeur_sms,
                            prix_annonceur_cpd: $prix_annonceur_cpd,
                            prix_editeur_cpd: $prix_editeur_cpd,
                            prix_annonceur_cpa_fixe: $prix_annonceur_cpa_fixe,
                            prix_editeur_cpa_fixe: $prix_editeur_cpa_fixe,
                            prix_annonceur_enrichissement: $prix_annonceur_enrichissement,
                            prix_editeur_enrichissement: $prix_editeur_enrichissement,
                            autorise: $autorise,
                            no_autorise: $no_autorise,
                            description: $description,
                            location: "",
                            pixel: "",
                            provenance: $provenance,
                            type: $type,
                            display: $display,
                            NbrCat: 0,
                            send_mail: false,
                            retargeting: $retargeting,
                            statut: $statut,
                            id_manager: $id_manager,
                            url_flux: $url_flux,
                            item_childs: $item_childs,
                            cpc_dyn: $cpc_dyn,
                            tracking_securise: $tracking_securise,
                            catalogue: $catalogue
                          )
          {
            id_programme
          }
        }`,
        variables: {
          id_programme: this.id_programme_selected,
          type: !this.VisibleSelects.Visible,
          display: this.VisibleSelects.Visible,
          file: this.images.profile,
          id_client: this.AnnonceurSelects.AnnonceurEdit,
          Titre: this.Titre,
          campagne: Compagne,
          format: format.slice(0,format.length-1),
          //lien: this.lien,
          categorie: this.ThematiqueSelects.ThematiqueEdit,
          langue: this.LangueSelects.Langue,
          etat: this.EtatSelects.Etat,
          manager: this.ManagerSelects.ManagerEdit.split("SPLITE")[0],
          autorise: autorise.slice(0, autorise.length-1),
          no_autorise: no_autorise.slice(0, no_autorise.length-1),
          statut: this.StatutSelects.Statut,
          url_flux: this.URL_Flux_XML,
          item_childs: this.Cle_items_XML,
          id_manager: this.ManagerSelects.ManagerEdit.split("SPLITE")[1],
          description: this.Description,
          retargeting: (this.Retargeting) ? 1 : 0,
          // prix
          prix_annonceur: this.prix_annonceur,
          prix_editeur: this.prix_editeur,
          prix_annonceur_cpc: this.prix_annonceur_cpc,
          prix_editeur_cpc: this.prix_editeur_cpc,
          prix_annonceur_cpm: this.prix_annonceur_cpm,
          prix_editeur_cpm: this.prix_editeur_cpm,
          prix_annonceur_cpa: this.prix_annonceur_cpa,
          prix_editeur_cpa: this.prix_editeur_cpa,
          prix_annonceur_sms: this.prix_annonceur_sms,
          prix_editeur_sms: this.prix_editeur_sms,
          prix_annonceur_cpd: this.prix_annonceur_cpd,
          prix_editeur_cpd: this.prix_editeur_cpd,
          prix_annonceur_cpa_fixe: this.prix_annonceur_cpa_fixe,
          prix_editeur_cpa_fixe: this.prix_editeur_cpa_fixe,
          prix_annonceur_enrichissement: this.prix_annonceur_enrichissement,
          prix_editeur_enrichissement: this.prix_editeur_enrichissement,
          cpc_dyn: this.CPCDynamiqueSelects.CPCDynamique,
          tracking_securise: this.SecureTrackingSelects.SecureTracking,
          catalogue: this.CatalogueSelects.Catalogue
        },
      }).then(response => {
        this.$notify({type: 'success', message: this.$t("apiresponse.prgramupdated") })
        this.EditProgrmShow = false
        // refetch querys
        this.$apollo.queries.nouveautes.refetch({
          role: "ad"
        })
        this.$apollo.queries.listAnnonces.refetch({
          id_client: null,
          droit: "ad",
          langue: "FR",
          state_Delete: false
        })
      }).catch(error => {
        this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
      })
    }
  },
  computed: {
    filterlistAnnonces() {
      var list = null;
      try {
        list = this.listAnnonces.filter(annonce => {
          return (
            annonce.categorie.match(this.ThematiqueSelects.Thematique) &&
            annonce.manager.match(this.ManagerSelects.Manager.split("SPLITE")[0]) &&
            (annonce.id_client + "").match(this.AnnonceurSelects.Annonceur) &&
            annonce.format.match(this.TypeDiffusionSelects.TypeDiffusion) &&
            annonce.campagne.match(
              this.ModeRemunerationSelects.ModeRemuneration
            ) &&
            annonce.Titre.toUpperCase().match(this.keyword.toUpperCase()) &&
            annonce.langue.toUpperCase().match(this.PaysSelects.Pays.toUpperCase()) &&
            annonce.etat.toUpperCase().match(this.StatSelects.Stat.toUpperCase()) &&
            (annonce.display+"").match(this.DisplaySelects.Display)
          );
        });
        this.total = list.length;
        if(list != null) {
          return list.slice(this.from, this.to);
        }
        else
          return null
      } catch (error) {
        return null;
      }
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    }
  }
};
</script>

<style scoped>
.btn {
  white-space: nowrap;
}
.prog {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}
.prog img {
  width: 100%;
}
.tm_photo {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}
.tm_photo img {
  width: 100%;
}
.phototm {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  vertical-align: middle;
  box-shadow: 0px 0px 5px #000;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
}

.campagnes {
  background: #ffffff;
  padding: 10px 0px;
  margin: 5px 0;
  border-radius: 10px;
  box-shadow: 0px 0px 2px #999;
  background: linear-gradient(0deg, #51357c 0%, #988fc3 65%, #ffffff 66%);
  border: 3px solid #fff !important;
}
.campagnes h6 {
  background: #51357c;
  color: #fff;
  margin: -10px auto 10px;
  -webkit-border-bottom-right-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-bottomright: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 80%;
  font-size: 12px;
}
.campagnes h5 {
  background: #fff;
  margin: 10px 0px;
  padding: 5px 0;
  color: #54347c;
  font-weight: bold;
}
.campagnes img {
  box-shadow: 0px 0px 2px #999;
}
.campagnes button {
  width: 80%;
}
.campagnes button.btn {
  padding: 5px;
}

.card .card-header h5.card-category {
  color: #fff;
}
.white-content .card .card-header h5.card-category {
  color: #1d253b;
}
.white-content .btn-neutral.btn-simple {
  color: #000;
  border-color: #000;
  background: transparent;
}

/** Card **/
.card-custom {
  overflow: hidden;
  min-height: 300px;
  box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
  width: 200px;
  margin: 0 auto;
}
.card-custom .card-body {
  margin-top: -75px;
  text-align: right;
  padding: 15px 15px 0;
  z-index: 999999;
}
.card-custom .card-body p {
  text-align: left;
}
.card-custom-img {
  height: 200px;
  min-height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-color: inherit;
}
.card-custom-img h6 {
  text-transform: none;
}
.card-custom .card-body .card-title {
  color: #fff;
  font-weight: bold;
  font-size: 12px;
}
/*#program .card-custom .card-body .card-title {min-height:60px}*/
.card-custom .card-body .card-text {
  color: #000;
}
/* First border-left-width setting is a fallback */
#program .card-footer {
  margin-top: 0;
}
.card-custom-img::after {
  position: absolute;
  content: "";
  top: 161px;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-top-width: 40px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-left-width: 545px;
  border-left-width: calc(575px - 5vw);
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: inherit;
}

.card-custom-avatar img {
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
  position: absolute;
  z-index: 99999999;
  top: 120px;
  left: 1.25rem;
  width: 35px;
  height: 35px;
}
</style>

<style>
#carousel3d .carousel-3d-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  padding: 0px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  border-radius: 10px;
}

#carousel3d .carousel-3d-slide img {
  width: auto !important;
}
#carousel3d .carousel-3d-slide .card {
  margin-bottom: 0px;
}

#carousel3d .carousel-3d-controls a.prev,
#carousel3d .carousel-3d-controls a.next {
  color: #fff;
}
#carousel3d .carousel-3d-slider {
  position: relative;
  margin: 0 auto;
  transform-style: none !important;
  -webkit-perspective: none !important;
  -moz-perspective: none !important;
  perspective: none !important;
  width: 200px !important;
}
.costom_slide {
  height: 295px !important;
  width: 200px !important;
  padding: 10px;
}
.icon-thematique {
  width: 20px;
  height: 20px;
  position: relative;
}
</style>
